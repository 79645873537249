/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ConnectionStatus {
    CONNECTED = 'Connected',
    DISCONNECTED = 'Disconnected',
    IDLE = 'Idle',
}
