import Chatbot from "@assets/Chatbot.svg?react";
import React from "react";

interface GenAiButtonProps {
  onClick: () => void;
}
const GenAiButton: React.FC<GenAiButtonProps> = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={"flex flex-col items-center justify-center"}
    >
      <div
        className={
          "flex h-[48px] w-[48px] items-center justify-center rounded-full bg-mariana-blue hover:hover:bg-blaze-orange"
        }
      >
        <Chatbot className={"h-[38x] w-[38px] "} />
      </div>
      <p
        className={
          "hidden py-2 font-medium dark:text-white text-tolopea sm:block"
        }
      >
        AI
      </p>
    </button>
  );
};

export { GenAiButton };
