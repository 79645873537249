/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum GameStatus {
    WAITING_FOR_ROUND_START = 'WaitingForRoundStart',
    ANSWERING = 'Answering',
    POST_ANSWERING = 'PostAnswering',
    VOTING = 'Voting',
    POST_VOTING = 'PostVoting',
    IN_GAME = 'InGame',
    IN_LOBBY = 'InLobby',
    ENDED = 'Ended',
}
