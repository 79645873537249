import type {
    GameQuestionSchema,
    GameStatus,
    GameTypes,
    StudyCardSchema,
} from '@source/client'
import type { Answer } from '@store/answers/answersSlice'
import type React from 'react'
import type { Swiper as SwiperType } from 'swiper/types'

export type NonEmptyArray<T> = [T, ...T[]]

export interface Player {
    id: number
    player_id: number
    username: string
    game_id: number
    turns_as_main_player: number
    points: number
    status: string
    times_correct: number
    times_deceiver: number
}
export interface IGameStartedData {
    type: string
    game: {
        id: number
        creator: number
        current_flashcard_id: number
        deck_id: number
        rounds: number
        time_limit: number
        time_limit_vote: number
        time_created: string
        start_time: string
        current_round: number
    }
    players: Player[]
}

export interface IRoundStartedData {
    type: string
    roundId: number
    questionType: string
    timeLimit: number
    question: string
}

// TODO: Eventually need to remove any from here once we have a better idea of the other types (one of them being mcq)
export type IAnswers = Record<string, string>

export interface ICountedVote {
    id: number
    gameId: number
    round: number
    playerId: number
    isCorrect: number
    pointsAnswer: number
    answer: string
    deceiver: string
    pointsDeceiver: number
    totalPoints: number
    voteId: number
    voteText: string
    counted: boolean
}

export enum PlayerStatus {
    Waiting = 'Waiting',
    Playing = 'Playing',
    Played = 'Played',
    Absent = 'Absent',
    Ready = 'Ready',
    Voted = 'Voted',
}

export interface IGame {
    id: number
    type: GameTypes
    creator: number
    creatorUsername: string
    hostUsername: string
    status: GameStatus
    currentHost: number
    deckId: number
    rounds: number
    timeLimit?: number
    timeLimitVote?: number
    timeCreated: string
    startTime?: string
    currentRound: number
    pointsCorrect?: number
    pointsDeceiver?: number
    // askedQuestions?: Array<number>;
}
export interface IMcq {
    correct: string
    wrong1: string
    wrong2: string
    wrong3: string
}

export enum RoundStatus {
    NotStarted = 'NotStarted',
    InProgress = 'InProgress',
    Completed = 'Completed',
    AwaitingAnswers = 'AwaitingAnswers',
    AwaitingVotes = 'AwaitingVotes',
    AnswersSubmitted = 'AnswersSubmitted',
    VotesSubmitted = 'VotesSubmitted',
    AwaitingPlayers = 'AwaitingPlayers',
    Paused = 'Paused',
}

// rename these fields
export interface IGameQuestion {
    type: GameTypes
    cardId: number | null
    question: string | null
    answer: string | null | IMcq
    questionCategory: string | null
    img: string | null // url
    sound: string | null // url
}

//TODO: This is manually made, would be better if it was autogenerated like the others
export interface GameRoundResultsSchema {
    id: number
    gameId: number
    round: number
    playerId: number
    isCorrect?: boolean
    pointsAnswer: number
    answer?: string
    deceiver?: boolean
    gameQuestion: GameQuestionSchema
    pointsDeceiver?: number
    totalPointsRound?: number
    totalPointsGame?: number
    voteId?: number
    voteText?: number
    counted?: boolean
    totalPoints?: number
}

export interface IVote {
    round: number
    voteId: string
    voteText: string
    playerId: number
    username: string
}

export interface IGameRounds {
    id: string // should be a combination of game_id and round_id
    gameId: number
    roundId: number
    status: RoundStatus
    results: GameRoundResultsSchema[] //players results for each rounds
    question: GameQuestionSchema
}

export interface IGameState {
    state:
        | 'WaitingForRoundStart'
        | 'Voting'
        | 'PostVoting'
        | 'InGame'
        | 'InLobby'
        | 'Ended'
}
export interface ITimerState {
    state: 'idle' | 'going' | 'ended'
    context: 'game' | 'vote' | 'quiz'
}
// DEPRECATED
// export interface IGameContext {
//   gameId?: number | string | null;
//   steps: number;
//   isHost: boolean;
//   endGameDetails: Player[] | undefined;
//   startedGameData: IGameStartedData | null;
//   gameDetails: NewGameDataResponse | null;
//   gameState: IGameState;
//   socketRef: React.MutableRefObject<WebSocket | null>;
//   setGameState: React.Dispatch<React.SetStateAction<IGameState>>;
//   setStartedGameData: React.Dispatch<
//     React.SetStateAction<IGameStartedData | null>
//   >;
//   setEndGameDetails: React.Dispatch<React.SetStateAction<Player[] | undefined>>;
//   user: UserState;
//   time: number;
//   roundDetails: IRoundStartedData | null;
//   answers: IAnswers[] | null;
//   players: IPlayer[];
//   forced: boolean;
//   votes: ICountedVote[] | null;
//   playersActions: {
//     playerId: string;
//     voted: boolean;
//     answered: boolean;
//   }[];
// }
export interface IStudyContext {
    correctPercent: number
    leftToStudy: number
    activeCard: StudyCardSchema | null
    setActiveCard: React.Dispatch<React.SetStateAction<StudyCardSchema | null>>
    handleRemoveOneCardFromHistory: () => void
    cards: StudyCardSchema[]
    cardsStatus: string
    isLoading: boolean
    cardsHistory: StudyCardSchema[]
    currentDeckAnswers: Answer[]
    onSliderChange: (e: SwiperType) => void
    skippedCards: number
    noMoreCards: boolean
}
