import type { QuestionSchema } from '@client/index'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { RootState } from '@store/store'

import { updateOneQuiz } from '../quizzes/actions'
import { logoutUser } from '../user/actions'
import {
    addManyTempQuestions,
    addOneTempQuestion,
    clearAllTempQuestions,
    toggleAllTempQuestionsSelected,
    toggleSelected,
    updateManyTempQuestions,
    updateOneTempQuestion,
} from './actions'

export interface TempQuestionSchema extends QuestionSchema {
    selected: boolean
    jeopardy: boolean
}

const tempQuestionsAdapter = createEntityAdapter<TempQuestionSchema>({
    sortComparer: (a, b) => (a.qOrder ?? 1) - (b.qOrder ?? 2),
})

const initialState = tempQuestionsAdapter.getInitialState({
    status: 'idle',
    error: '',
})
//NOTE: the ids here correspond to the card ids, we should never use the card ID to modify a question

const questionsSlice = createSlice({
    name: 'questions',
    initialState,
    reducers: {
        upsertOneQuestion: tempQuestionsAdapter.upsertOne,
        updateOneQuestion: tempQuestionsAdapter.updateOne,
        upsertManyQuestions: tempQuestionsAdapter.upsertMany,
        setQuestions: tempQuestionsAdapter.setAll,
        updateManyQuestions: tempQuestionsAdapter.updateMany,
        addOneTempQuestion: tempQuestionsAdapter.addOne,
    },

    extraReducers: (builder) => {
        builder.addCase(updateOneQuiz.fulfilled, (state, action) => {
            tempQuestionsAdapter.removeAll(state)
            if (action.payload.quizzes[0]?.questions == null) return
            tempQuestionsAdapter.upsertMany(
                state,
                action.payload.quizzes[0].questions
            )
            state.status = 'succeeded'
        })
        builder.addCase(logoutUser.fulfilled, (state) => {
            tempQuestionsAdapter.removeAll(state)
        })
        builder.addCase(addManyTempQuestions, (state, action) => {
            tempQuestionsAdapter.upsertMany(state, action.payload.questions)
            state.status = 'succeeded'
        })
        builder.addCase(updateOneTempQuestion, (state, action) => {
            if (action.payload.question != null) {
                tempQuestionsAdapter.upsertOne(state, action.payload)
            }
        })
        builder.addCase(updateManyTempQuestions, (state, action) => {
            tempQuestionsAdapter.upsertMany(state, action.payload)
        })
        builder.addCase(addOneTempQuestion, (state, action) => {
            tempQuestionsAdapter.upsertOne(state, action.payload)
        })
        builder
            .addCase(clearAllTempQuestions, (state) => {
                tempQuestionsAdapter.removeAll(state)
            })
            .addCase(toggleSelected, (state, action) => {
                const question = state.entities[action.payload]
                if (question != null) {
                    tempQuestionsAdapter.updateOne(state, {
                        id: question.id,
                        changes: { selected: !question.selected },
                    })
                }
            })
            .addCase(toggleAllTempQuestionsSelected, (state, action) => {
                const tempsQuestions = tempQuestionsAdapter
                    .getSelectors()
                    .selectAll(state)
                tempsQuestions.forEach((question) => {
                    tempQuestionsAdapter.updateOne(state, {
                        id: question.id,
                        changes: { selected: action.payload },
                    })
                })
            })
    },
})

export default questionsSlice.reducer
export const {
    selectAll: selectAllTempQuestions,
    selectById: selectTempQuestionById,
    selectIds: selectTempQuestionsIds,
} = tempQuestionsAdapter.getSelectors((state: RootState) => state.tempQuestions)
