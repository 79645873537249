const FeedbackOptions = [
  {
    value: 0,
    label: "Bug",
  },
  {
    value: 1,
    label: "Feature Request",
  },
  {
    value: 2,
    label: "Other",
  },
];

export { FeedbackOptions };
