import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { type StudyCardSchema } from "@source/client";
import { type RootState } from "@store/store";

import { logoutUser } from "../user/actions";
import {
  deleteOneCard,
  fetchDueCards,
  fetchDueNextCards,
  updateOne,
} from "./actions";

const cardInstancesAdapter = createEntityAdapter<StudyCardSchema>({
  selectId: (card) => card.uniqueId,
  // sortComparer: (a, b) => a.uniqueId - b.uniqueId,
});

const initialState = cardInstancesAdapter.getInitialState({
  status: "idle",
  error: "",
  batchNumber: 0,
});
const cardInstancesSlice = createSlice({
  name: "cardInstances",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      //   For the first render, fetch the due cards
      .addCase(fetchDueCards.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDueCards.fulfilled, (state, action) => {
        state.status = "succeeded";
        cardInstancesAdapter.setAll(state, action.payload.cards);
        state.batchNumber = action.payload.batchNumber;
      })
      .addCase(fetchDueCards.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? "";
      })
      //    FETCH DUE NEXT CARDS
      .addCase(fetchDueNextCards.fulfilled, (state, action) => {
        state.status = "succeeded";
        cardInstancesAdapter.addMany(state, action.payload.cards);
        state.batchNumber = action.payload.batchNumber;
      })

      //   DELETE ONE CARD
      .addCase(deleteOneCard.fulfilled, (state, action) => {
        state.status = "succeeded";
        cardInstancesAdapter.removeOne(state, action.payload.cardId);
      })
      .addCase(deleteOneCard.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? "";
      })
      //   UPDATE ONE CARD
      .addCase(updateOne.fulfilled, (state, action) => {
        state.status = "succeeded";
        const updatedCard = action.payload;
        cardInstancesAdapter.setOne(state, updatedCard);
      })
      .addCase(updateOne.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? "";
      })
      .addCase(logoutUser.fulfilled, (state) => {
        cardInstancesAdapter.removeAll(state);
        state.status = "idle";
      });
  },
});

export default cardInstancesSlice.reducer;
export const {
  selectAll: selectAllCardInstances,
  selectById: selectCardInstancesById,
  selectIds: selectCardInstanceIds,
} = cardInstancesAdapter.getSelectors(
  (state: RootState) => state.cardInstances
);
