import MSIcon from "@assets/signin/MSIcon.png";
import React from "react";

// import MsSignInLight from "./assets/MsSignInLight.svg?url";

const MICROSOFT_REDIRECT_URI = import.meta.env.VITE_MICROSOFT_REDIRECT_URI;
const MICROSOFT_CLIENT_ID = import.meta.env.VITE_MICROSOFT_CLIENT_ID;

const MSLoginButton = (): React.ReactNode => {
  const handleMicrosoftLogin = (): void => {
    const originalPage = window.location.pathname;
    const state = encodeURIComponent(originalPage);
    const microsoftAuthUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${MICROSOFT_CLIENT_ID}&redirect_uri=${encodeURIComponent(
      MICROSOFT_REDIRECT_URI
    )}&response_type=code&scope=openid%20profile%20email%20User.Read&state=${encodeURIComponent(
      state
    )}`;
    window.location.href = microsoftAuthUrl;
  };

  return (
    <button
      className=" flex w-[266px] items-center rounded-full border border-gray-300 bg-white px-6 py-2 text-sm font-medium text-slate-900 shadow-md focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
      onClick={handleMicrosoftLogin}
    >
      <div className="flex items-center justify-between gap-2 text-slate-700">
        <img
          src={MSIcon}
          alt="Microsoft"
          className="microsoft-logo rounded-full"
        />
        <p> Sign in with Microsoft</p>
      </div>
    </button>
  );
};

export { MSLoginButton };
