/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PlayerStatus {
    WAITING = 'Waiting',
    PLAYING = 'Playing',
    PLAYED = 'Played',
    ABSENT = 'Absent',
    READY = 'Ready',
    VOTED = 'Voted',
}
